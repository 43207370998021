const translations = {
  en: {
    organizations: "Organizations",
    organization: "Organization",
    departments: "Departments",
    department: "Department",
    departmentRef: "Department Reference indicator",
    dashboard: "Dashboard",
    contractNumber: "Contract Number",
    home: "Home",
    tracking: "Tracking",
    trackingHistory: "Tracking History",
    inbox: "Inbox",
    alerts: "Alerts",
    vehicle: "Vehicle",
    enterYourDetails: "Please enter your details",
    bulkEditContractor: "Bulk Edit Contractor",
    CheckedInVehicle: "Checked In Vehicle",
    transportation: "Transportation",
    vehicles: "Vehicles",
    reports: "Reports",
    boardingLog: "Boarding Log",
    bookings: "Bookings",
    parkAndRide: "Park & Ride",
    routePlanning: "Route Planning",
    accumulativeDistance: "Accumulative Distance",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    users: "Users",
    user: "User",
    contractors: "Contractors",
    contractor: "Contractor",
    staff: "PDO Staff",
    focalPoints: "PDO Focal Points",
    focalPoint: "PDO Focal Point",
    contractFocalPoints: "Contract Focal Points",
    contractFocalPoint: "Contract Focal Point",
    drivers: "Drivers",
    driver: "Driver",
    busOperator: "Bus Operator",
    busOperators: "Bus Operators",
    registrationRequests: "Registration Requests",
    contracts: "Contracts",
    notifications: "Notifications",
    hardware: "Hardware",
    batch: "Batch",
    role: "Role",
    supportTickets: "Support Tickets",
    devTools: "Dev Tools",
    settings: "Settings",
    helpSupport: "Help & Support",
    noAccount: "Don't have account?",
    pendingApproval: "Pending Approval",
    pendingRegistrationMessage:
      " Once approved by focal point, a confirmation will be sent to your email address",
    alreadyRegistered: "Already registered?",
    login: "Login",
    loggingIn: "Logging In...",
    register: "Register",
    forgotPassword: "Forgot Password?",
    conPassword: "Confirm Password",
    noPermission: "Sorry, you don't have permission to view this page",
    accountDisabled:
      "Your account is disabled. Please call school administration for further information.",
    logoutCountdown: (seconds) => `Logging you out in ${seconds} seconds`,
    assign: "Assign",
    unassign: "Remove",
    type: "Type",

    id: "ID",
    name: "Name",
    date: "Time",
    time: "Time",
    email: "Email",

    parkActiveStatus: "Active",
    parkInactiveStatus: "Inactive",
    typeOfConfirmation: "Type Of Confirmation",
    firstName: "First Name",
    middleName: "Middle Name",
    firstNameIsRequired: "First name is required",
    middleNameIsRequired: "First name is required",
    secondName: "Second Name",
    lastName: "Last Name",
    lastNameIsRequired: "Last name is required",
    phoneNumber: "Phone Number",
    phoneNumberIsRequired: "Phone number is required",
    contractId: "Contract Identifier",
    contractIdIsRequired: "Contract identifier is required",
    username: "Username",
    usernameEmail: "Username/Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    changePassword: "Change Password",
    changingPassword: "Changing Password...",
    phone: "Phone",
    phoneAlt: "Phone (Alternative)",
    countryCode: "Country Code",
    countryCodeIsRequired: "Country code is required",
    countryCodeStartWithPlus: "Country code must start with plus (+) sign",
    countryCodeNoAlphabetic:
      "Country code must contain numbers and plus (+) sign",
    language: "Language",
    city: "City",
    selectCity: "Select City",
    address: "Address",
    state: "State",
    suburb: "Suburb",
    country: "Country",
    area: "Area",
    selectArea: "Select Area",
    profile: "Profile",
    accountSettings: "Account Settings",
    logout: "Logout",
    loading: "Loading...",
    cancel: "Cancel",
    save: "Save",
    close: "Close",
    confirm: "Confirm",
    delete: "Delete",
    status: "Status",
    archived: "Archived",
    unArchived: "Un Archived",
    active: "Active",
    inactive: "Inactive",
    disabled: "Disabled",
    muscat: "Muscat",
    buraymi: "Al Buraymi",
    dakhiliyah: "Ad Dakhiliyah",
    dhahirah: "Ad Dhahirah",
    batinahNorth: "Al Batinah North",
    batinahSouth: "Al Batinah South",
    wusta: "Al Wusta",
    sharqiyahNorth: "Ash Sharqiyah North",
    sharqiyahSouth: "Ash Sharqiyah South",
    dhofar: "Dhofar",
    musandam: "Musandam",

    phoneIsRequired: "Phone is required",
    phoneIsShort: "Phone number must contain at least 8 digits",
    phoneIsNumeric: "Phone number must contain numbers",
    emailIsRequired: "Email is required",
    emailIncorrect: "Email format is incorrect",
    companyNumber: "Company number",
    referenceIndicator: "Employee Reference indicator",
    companyNumberIsRequired: "Company number is required",
    referenceIndicatorIsRequired: "Reference indicator is required",
    contractIsRequired: "Contract number is required",
    departmentIsRequired: "Department is required",
    departmentRefIsRequired: "Department Ref is required",
    contractExpired: "Contract expired. Please enter active contract",
    countryIsRequired: "Country is required",
    departmentNotExist: "Entered department does not exist",
    emailIncorrect: "Email incorrect.",
    search: "Search",
    edit: "Edit",
    filter: "Filter",
    clearFilters: "Clear Filters",
    upload: "Upload File",
    processingFile: "Processing file, Please wait.",
    files: "Files",
    next: "Next",
    back: "Back",
    done: "Done",
    previous: "Previous",
    uploadFileInstructions:
      "Drag & drop some files here, or click to select files",
    downloadCsvFile: "Download CSV file",
    bulkAddErrorsFound:
      "The following errors are found in the uploaded file. Please update it and upload it again.",
    invalidUsernameOrPassword: "Incorrect username or password",

    muiDatatable: {
      body: {
        noMatch: "Sorry, no matching records found",
        loadingEntity: (entity) => `Loading ${entity}. Please wait....`,
        // toolTip: "Sort",
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "rows(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Rows",
      },
    },
    //yet to be translated
    Trips: "Trips",
    accumilativeDistance: "Acuumilative Distance",
    accumilativeDistanceWillBeCalculatedAutomatically:
      " Accumilative Distance Will Be Calculated Automatically",
    approvers: "Approvers",
    adminUsers: "Admin Users",
    invalidOtp: "Invalid OTP code",
    otpExpiresIn: "Your OTP expires in",
    timeout: "Server down, Please try in a while",
    reSendCode: "Re-send Code",
    expiresIn: "Expires In",
    forgetPwSuc: "A confirmation mail has been sent to provided email address ",
    forgetPwErr: "There were problem sending mail to provided email address",
    pdoFocalPoint: "PDO Focal point",
    contractFocalPoint: "Contract Focal point",
    verify: "Verify",
    resetPw: "Reset Password",
    backToLogin: " Back to Login",
    capacityIsRequired: "Capacity is required",
    containNumber: (val) => `${val} should contain only Numbers`,
    directorate: "Directorate",
    company_number: "Company Number",
    reference_indicator: "Reference Indicator",
    reject: "Reject",
    retry: "Re-Try",
    refresh: "Refresh",
    tutorial: "Tutorial",
    errors: "Error(s)",
    bulkEditContractor: "Bulk Edit Contractor",

    contractExpired: "Contract Expired",
    isNonAlphabeticError: "Please only use English alphabets",
    isAlphaNumericSymError:
      "Please only use English alphanumeric chars and symbols",

    char8: "8 characters or more",
    upperCase: "At least one upper case",
    lowerCase: "At least one lower case",
    oneNumber: "At least one number",
    oneSymbol: "At least one symbol",
    passwordPolicy: "Password Policy",
    passwordIsRequired: "Password is required",
    passwordDoesNotMeetPasswordPolicy: "Password is not strong enough",
    weakPassword: "Password is weak",
    goodPassword: "Password is not strong enough",
    strongPassword: "Password is strong",

    dataLastFetched: (min) => {
      let unit = min >= 60 ? "hr" : "min";
      let val = min >= 60 ? min / 60 : min;
      return `Data last updated ${Math.round(val) || "X"} ${
        unit === "hr" ? "hour" : "minutes"
      } ago.`;
    },
    invalidEmail: (name) => `${name} Is Invalid.`,
    invalidDate: `Date is invalid`,
    plateEmail: "Vehicle Plate no.",
    isRequired: (val) => `${val} is required`,
    createdAt: "Created At",
    updatedAt: "Updated At",
    action: "Action",
    submit: "Submit",
    select: "Select",
    reload: "Reload",
    loginUsingAzure: "Login with PDO account?",

    later: "Later",
    pageWillAutoReload: "The page will auto reload on",
    region: "Region",
    selectRegion: "Select Region",
    selectBusOperatorRole: "Select Bus Operator Role",
    isRequired: (val) => `${val} is required.`,
    oneOrMore: "one or more",
    ReqTimeErrMsg1: "Request cannot be completed. ",
    ReqTimeErrMsg2:
      "You may be experiencing a slow connection or something went wrong on the server.",
    cost: "Cost",
    sector: "Sector",
    booking: "Booking",
    dateTo: "Date to",
    capacity: "Capacity",
    filterBy: "Filter by",
    dateFrom: "Date From",
    bookingType: "Booking Type",
    routeGroups: "Route Groups",
    noOfBooking: "No of booking",
    bookingStatus: "Booking Status",
    seatUtilization: "Seat Utilization",
    summaryOfBookings: "Summary of bookings",
    percentageOfBookingByLocation: "Percentage of Bookings by Locations",
    seatUtilizationPercentageBySector: "Seat Utilization Percentage By Sector",
    dateDiffLessThan90days:
      "Date difference should not be greater than 90 days",
    routeGroup: "Route group",
    selectRouteGroup: "Select route group",
    regions: "Regions",
    selectRegions: "Select regions",
    utilization: "Utilization",
    pickupStop: "Pick-up Stop",
    selectPickupStop: "Select pick-up stop",
    dropOffStop: "Drop-off Stop",
    selectDropOffStop: "Select drop-off stop",
    registeredSuccessfully: "Registerd successfully.",
    usernameAlreadyExist: "Username already exists.",
    phoneAlreadyExist: "Phone already exists.",

    customerCare: "Customer Care",
    workingHours: "Working Hours",
  },
  ar: {
    organizations: "المؤسسات",
    organization: "المؤسسة",
    departments: "Departments",
    department: "Department",
    departmentRef: "Department Reference indicator",
    tracking: "التتبع",
    trackingHistory: "سجل التتبع",
    dashboard: "الرئيسية",
    contractNumber: "Contract Number",
    home: "الرئيسية",
    inbox: "صندوق الوارد",
    alerts: "التنبيهات",
    vehicle: "وسائل النقل",
    enterYourDetails: "الرجاء إدخال التفاصيل الخاصة بك",
    transportation: "وسائل النقل",

    vehicles: "المركبات",
    reports: "تقارير",
    boardingLog: "سجل الركوب",
    bookings: "Bookings",
    routePlanning: "Route Planning",
    all: "المستخدمين",
    users: "المستخدمين",
    user: "المستخدم",
    contractors: "الركاب",
    contractor: "الراكب",
    staff: "Staff",
    focalPoints: "أولياء الأمور",
    focalPoint: "ولي الأمر",
    contractFocalPoints: "المشرفين",
    contractFocalPoint: "المشرف",
    drivers: "السائقين",
    driver: "السائق",
    busOperator: "مهندس دعم",
    busOperatorRole: "نوع مهندس الدعم",
    busOperators: "مهندسي الدعم",
    registrationRequests: "Registration Requests",
    contracts: "Contracts",
    notifications: "Notifications",
    hardware: "المعدات",
    batch: "الدفعات",
    supportTickets: "تذاكر الدعم الفني",
    devTools: "ادوات التطوير",
    settings: "الإعدادات",
    helpSupport: "المساعدة والدعم",
    noAccount: "Don't have account?",
    pendingApproval: "Pending Approval",
    pendingRegistrationMessage:
      " Once approved by focal point, a confirmation will be sent to your email address",
    alreadyRegistered: "Already registered?",
    login: "Login",
    loggingIn: "Logging In...",
    register: "Register",
    forgotPassword: "Forgot Password?",
    conPassword: "Confirm Password",
    noPermission: "عذرا، ليس لديك الصلاحية لعرض هذه الصفحة",
    accountDisabled:
      "تم تعطيل حسابك. يرجى الاتصال بإدارة المدرسة لمزيد من المعلومات.",
    logoutCountdown: (seconds) => `سيتم تسجيل خروجك في ${seconds} ثانية`,

    assign: "تعيين",
    unassign: "إزالة",
    type: "Type",

    id: "المعرف",
    name: "الاسم",
    date: "الوقت",
    time: "الوقت",
    CheckedInVehicle: "Checked In Vehicle",
    username: "Username",
    usernameEmail: "Username/Email",
    password: "كلمة السر",
    confirmPassword: "تأكيد كلمة السر",
    changePassword: "تغيير كلمة السر",
    changingPassword: "يتم تغيير كلمة السر...",
    email: "البريد الالكتروني",
    firstName: "First Name",
    firstNameIsRequired: "First name is required",
    secondName: "Second Name",
    lastName: "Last Name",
    lastNameIsRequired: "Last name is required",
    phoneNumber: "Phone Number",
    phoneNumberIsRequired: "Phone number is required",
    contractId: "Contract Identifier",
    contractIdIsRequired: "Contract identifier is required",
    companyNumber: "Company number",
    referenceIndicator: "Employee Reference indicator",
    companyNumberIsRequired: "Company number is required",
    referenceIndicatorIsRequired: "Reference indicator is required",
    contractIsRequired: "Contract number is required",
    departmentIsRequired: "Department is required",
    departmentRefIsRequired: "Department Ref is required",
    contractExpired: "Contract expired. Please enter active contract",
    phone: "الهاتف",
    phoneAlt: "الهاتف (البديل)",
    countryCode: "رمز الدولة",
    countryCodeIsRequired: "رمز الدولة مطلوب",
    countryCodeStartWithPlus: "Country code must start with plus (+) sign",
    countryCodeNoAlphabetic:
      "Country code must contain numbers and plus (+) sign",
    language: "اللغة",
    city: "مدينة",
    selectCity: "اختر مدينة",
    address: "عنوان",
    state: "المنطقة/المحافظة",
    area: "المنطقة/المحافظة",
    selectArea: "اختر المنطقة/المحافظة",
    suburb: "ضاحية",
    country: "الدولة",
    countryIsRequired: "الدولة مطلوبة",
    departmentNotExist: "Entered department does not exist",
    emailIncorrect: "Email incorrect.",
    login: "تسجيل الدخول",
    profile: "الملف الشخصي",
    accountSettings: "إعدادت الحساب",
    logout: "تسجيل خروج",
    loading: "قيد التحمبل...",
    cancel: "إلغاء",
    save: "حفظ",
    close: "إغلاق",
    confirm: "Confirm",
    delete: "حذف",
    status: "الحالة",
    archived: "مؤرشف",
    unArchived: "غير مؤرشف",
    active: "مفعل",
    inactive: "غبر مفعل",
    disabled: "معطل",
    muscat: "مسقط",
    buraymi: "البريمي",
    dakhiliyah: "الداخلية",
    dhahirah: "الظاهرة",
    batinahNorth: "شمال الباطنة",
    batinahSouth: "جنوب الباطنة",
    wusta: "الوسطى",
    sharqiyahNorth: "شمال الشرقية",
    sharqiyahSouth: "جنوب الشرقية",
    dhofar: "ظفار",
    musandam: "مسندم",
    search: "بحث",
    edit: "تحرير",
    filter: "تصفية",
    clearFilters: "إعادة تعيين",
    upload: "رفع الملف",
    processingFile: "Processing file, Please wait.",
    files: "Files",
    next: "Next",
    back: "Back",
    done: "Done",
    previous: "Previous",
    uploadFileInstructions:
      "Drag & drop some files here, or click to select files",
    downloadCsvFile: "Download CSV file",
    bulkAddErrorsFound:
      "The following errors are found in the uploaded file. Please update it and upload it again.",
    invalidUsernameOrPassword: "Incorrect username or password",

    muiDatatable: {
      body: {
        noMatch: "عذرا ، لم يتم العثور على سجلات مطابقة",
        loadingEntity: (entity) => `Loading ${entity}. Please wait....`,
        // toolTip: "فرز",
      },
      pagination: {
        next: "الصفحة التالية",
        previous: "الصفحة السابقة",
        rowsPerPage: "الصفوف في كل صفحة:",
        displayRows: "من",
      },
      toolbar: {
        search: "بحث",
        downloadCsv: "تنزيل ملف CSV",
        print: "طباعة",
        viewColumns: "عرض الأعمدة",
        filterTable: "تصفية الجدول",
      },
      filter: {
        all: "الكل",
        title: "الفلاتر",
        reset: "إعادة تعيين",
      },
      viewColumns: {
        title: "عرض الأعمدة",
        titleAria: "إظهار / إخفاء أعمدة الجدول",
      },
      selectedRows: {
        text: "الصفوف المحددة",
        delete: "حذف",
        deleteAria: "حذف الصفوف المحددة",
      },
    },
    //yet to be translated
    Trips: "Trips",
    approvers: "Approvers",
    adminUsers: "Admin Users",
    invalidOtp: "Invalid OTP code",
    otpExpiresIn: "Your OTP expires in",
    timeout: "Server down, Please try in a while",
    reSendCode: "Re-send Code",
    expiresIn: "Expires In",
    forgetPwSuc: "A confirmation mail has been sent to provided email address ",
    forgetPwErr: "There were problem sending mail to provided email address",
    pdoFocalPoint: "PDO Focal point",
    contractFocalPoint: "Contract Focal point",
    verify: "Verify",
    resetPw: "Reset Password",
    backToLogin: "Back to Login",
    capacityIsRequired: "Capacity is required",
    containNumber: (val) => `${val} should contain only Numbers`,
    directorate: "Directorate",
    company_number: "Company Number",
    reference_indicator: "Reference Indicator",
    reject: "Reject",
    retry: "Re-Try",
    refresh: "Refresh",
    accumulativeDistance: "Accumulative Distance",
    tutorial: "Tutorial",
    errors: "Error(s)",
    contractExpired: "Contract Expired",
    isNonAlphabeticError: "Please only use English alphabets",
    isAlphaNumericSymError:
      "Please only use English alphanumeric chars and symbols",
    loginUsingAzure: "Login with PDO account?",

    char8: "8 characters or more",
    upperCase: "At least one upper case",
    lowerCase: "At least one lower case",
    oneNumber: "At least one number",
    oneSymbol: "At least one symbol",
    passwordPolicy: "Password Policy",
    passwordIsRequired: "Password is required",
    passwordDoesNotMeetPasswordPolicy: "Password is not strong enough",
    busOperatorRole: "Bus Operator Role",
    weakPassword: "Password is weak",
    goodPassword: "Password is not strong enough",
    strongPassword: "Password is strong",
    dataLastFetched: (min) => {
      let unit = min >= 60 ? "hr" : "min";
      let val = min >= 60 ? min / 60 : min;
      return `Data last updated ${Math.round(val) || "X"} ${
        unit === "hr" ? "hour" : "minutes"
      } ago.`;
    },

    invalidEmail: (name) => `${name} Is Invalid.`,
    invalidDate: `Date is invalid`,
    plateEmail: "Vehicle Plate no.",
    isRequired: (val) => `${val} is required`,
    createdAt: "Created At",
    updatedAt: "Updated At",
    action: "Action",
    select: "Select",
    reload: "Reload",
    later: "Later",
    pageWillAutoReload: "The page will auto reload on",
    region: "Region",
    selectRegion: "Select Region",
    isRequired: (val) => `${val} is required.`,
    oneOrMore: "one or more",
    ReqTimeErrMsg1: "Request cannot be completed. ",
    ReqTimeErrMsg2:
      "You may be experiencing a slow connection or something went wrong on the server.",
    cost: "Cost",
    sector: "Sector",
    booking: "Booking",
    dateTo: "Date to",
    capacity: "Capacity",
    filterBy: "Filter by",
    dateFrom: "Date From",
    bookingType: "Booking Type",
    routeGroups: "Route Groups",
    noOfBooking: "No of booking",
    bookingStatus: "Booking Status",
    seatUtilization: "Seat Utilization",
    summaryOfBookings: "Summary of bookings",
    percentageOfBookingByLocation: "Percentage of Bookings by Locations",
    seatUtilizationPercentageBySector: "Seat Utilization Percentage By Sector",
    dateDiffLessThan90days:
      "Date difference should not be greater than 90 days",
    routeGroup: "Route group",
    selectRouteGroup: "Select route group",
    regions: "Regions",
    selectRegions: "Select Regions",
    utilization: "Utilization",
    pickupStop: "Pick-up Stop",
    selectPickupStop: "Select pick-up stop",
    dropOffStop: "Drop-off Stop",
    selectDropOffStop: "Select drop-off stop",
    registeredSuccessfully: "Registerd successfully.",
    usernameAlreadyExist: "Username already exists.",
    phoneAlreadyExist: "Phone already exists.",
    role: "Role",
    selectRole: "Select Role",

    customerCare: "Customer Care",
    workingHours: "Working Hours",
  },
};

export function getLabels(locale) {
  return translations[!locale ? "en" : locale];
}
export default function useLabels(locale) {
  return translations[!locale ? "en" : locale];
}
